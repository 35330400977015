export function removeAllFromClass(className, removeClass) {
    var elems = document.querySelectorAll('.' + className);
    [].forEach.call(elems, function(el) {
        el.classList.remove(removeClass)
    });
}

export function getElementOrParentByClass(event, className) {
    let domElem = false;
    if (event.target.matches('.' + className)) {
        domElem = event.target.matches('.' + className)
    } 
    else if (event.target.closest('.' + className)) {
        domElem = event.target.closest('.' + className)
    }
    return domElem
}