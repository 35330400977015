import { removeAllFromClass } from '../helpers/dom-helpers';

// DROPDOWNS
const dropdownClass = 'js-dropdown';
const dropdownTriggerClass = 'js-dropdown-trigger';
const activeClass = 'is-active';
document.addEventListener('click', function (e) {
    removeAllFromClass(dropdownTriggerClass, activeClass);
    if (e.target.matches('.' + dropdownTriggerClass)) {
        let domElem = e.target;

        e.preventDefault();
        domElem.classList.add(activeClass);
    }
});

document.addEventListener('scroll', function (e) {
    removeAllFromClass(dropdownTriggerClass, activeClass);
});

// RIGHT BORDER OVERFLOW FALLBACK
const dropdowns = document.getElementsByClassName(dropdownClass);
if (dropdowns.length > 0) {
    [].forEach.call(dropdowns, el => {
        var rect = el.getBoundingClientRect();
        if (
            rect.right >=
            (window.innerWidth || document.documentElement.clientWidth)
        ) {
            el.style.transform =
                'translate( ' + (window.innerWidth - rect.right) + 'px, 0)';
        }
    });
}

//MOBILE NAV
const mobileTriggerId = 'js-dropdown-main-trigger';
const mobileNavId = 'js-dropdown-main-nav';
const mobileTrigger = document.getElementById(mobileTriggerId);
const mobileNav = document.getElementById(mobileNavId);

mobileTrigger.addEventListener('click', event => {
    if (mobileTrigger.classList.contains(activeClass)) {
        mobileTrigger.classList.remove(activeClass);
        mobileNav.classList.remove(activeClass);
    } else {
        mobileTrigger.classList.add(activeClass);
        mobileNav.classList.add(activeClass);
    }
});
